import React, { useEffect } from "react";

const WinePlaylists = ({ playlists }) => {
  useEffect(() => {
    document.title = "Auburn Road - Wine Playlists";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);

  const renderPlaylists = playlists.map((item, i) => {
    if (item.playlists) {
      return (
        <div className="m-t-100">
          <h2>{item.wine}</h2>
          {item.playlists.map((x) => {
            return (
              <div className="d-flex">
                <div
                  className="m-b-20 w-100"
                  dangerouslySetInnerHTML={{ __html: x }}
                  key={i}
                />
              </div>
            );
          })}
        </div>
      );
    }
  });
  return (
    <div className="container inner-container p-t-200">
      <h2 className="huge-text-no-quote">Wine Playlists</h2>
      {renderPlaylists}
    </div>
  );
};

export default WinePlaylists;
