import React, { useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Dryrose from "../images/corner/corner-dry-rose-art.webp";
import GoodKarma from "../images/good-karma.png";
import Button from "../components/Button";
import moment from "moment";
import { useHistory } from "react-router";

const WineStories = (props) => {
  useEffect(() => {
    document.title = "Auburn Road - Wine Stories";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);
  const history = useHistory();
  const renderStories = () => {
    return props.stories.map((story, i) => {
      if (i === 0) return null;
      const title =
        story.fields.uniqueUrl ||
        story.fields.title.split(" ").join("-").toLowerCase();
      const image = story.fields.storyImage
        ? story.fields.storyImage.fields.file.url
        : GoodKarma;
      const style = {
        "--bg-image": `url(${image})`,
      };
      return (
        <div key={"wine-story-" + i} className="wine-story-wrap">
          <div
            className="story-image pointer"
            style={style}
            bg-image={image}
            onClick={() => history.push("/" + title)}
          ></div>
          <h3
            className="m-b-0 pointer"
            onClick={() => history.push("/" + title)}
          >
            {story.fields.title}
          </h3>
          <p className="i m-b-30">
            {moment(story.fields.publishDate).format("MMMM Do, YYYY") ||
              moment(story.sys.createdAt).format("MMMM Do, YYYY")}
          </p>
          <div className="blurb">
            {documentToReactComponents(story.fields.blurb)}
            <Button
              onClick={() => history.push("/" + title)}
              className="continue-reading"
              small
              type="plain"
            >
              continue reading
            </Button>
          </div>
        </div>
      );
    });
  };

  const renderFirstStory = () => {
    const first = props.stories[0];
    const title =
      first.fields.uniqueUrl ||
      first.fields.title.split(" ").join("-").toLowerCase();
    const image = first.fields.storyImage
      ? first.fields.storyImage.fields.file.url
      : GoodKarma;
    const style = {
      "--bg-image": `url(${image})`,
    };
    return (
      <div className="wine-story-wrap">
        <div
          className="story-image pointer"
          style={style}
          bg-image={image}
          onClick={() => history.push("/" + title)}
        ></div>
        <h3 className="m-b-0 pointer" onClick={() => history.push("/" + title)}>
          {first.fields.title}
        </h3>
        <p className="i m-b-30">
          {moment(first.fields.publishDate).format("MMMM Do, YYYY") ||
            moment(first.sys.createdAt).format("MMMM Do, YYYY")}
        </p>
        <div className="blurb">
          {documentToReactComponents(first.fields.blurb)}
          <Button
            onClick={() => history.push("/" + title)}
            className="continue-reading"
            small
            type="plain"
          >
            continue reading
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <img className="corner-dry-rose" src={Dryrose} alt="" />
      <div className="container m-b-100 p-t-150">
        <div className="text-center m-b-50 inner-container m-auto">
          <h1 className="huge-text-no-quote m-b-10">
            Wine <span className="accent-text">Stories</span>
          </h1>
          <h3 className=" p-b-50">like a blog, only cooler...</h3>
        </div>
        <div className="first-story m-auto m-b-50">{renderFirstStory()}</div>
        <div className="d-flex">
          <div className="story-grid">{renderStories()}</div>
        </div>
      </div>
    </>
  );
};

export default WineStories;
