import React from 'react';
import { withRouter } from "react-router-dom";
import Logo from '../images/logo-dark.svg';
import CornerLadybug from "../images/corner/corner-ladybug-art.webp"

const NotFoundPage = (props) => {
    return (
      <>
        <img className="corner-ladybug" src={CornerLadybug} alt=""/>
        <div className="container text-center p-t-150 p-b-200">
          <img className="width-300 m-auto m-b-30" src={Logo} alt="auburn road logo"/>
          <h2 className="huge-text-no-quote">Uh oh!</h2>
          <p>You've reached a page that doesnt exist.</p>
          <div className="m-auto justify-content-center">
            <p><a href="/">Go to home page</a></p>
            <p><a href="/our-wines">Check out our wines</a></p>
          </div>
        </div>
      </>
    )
}

export default withRouter(NotFoundPage);
