import React from 'react';
import Button from "../components/Button";
import { withRouter } from 'react-router-dom';
import Line from "../images/brush-hr.svg"
import { useHistory } from "react-router";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const WineStaticPage = (props) => {
    const history = useHistory();
  
    const renderGrape = () => {
      return (
        <>
          <h2 className="m-b-20">the grape</h2>
          {documentToReactComponents(info.theGrape)}
        </>
      )
    }
    const renderWine = () => {
      return (
        <>
          <h2 className="m-b-20">the wine</h2>
          {documentToReactComponents(info.theWine)}
        </>
      )
    }
    const renderName = () => {
      return (
        <>
          <h2 className="m-b-20">the name</h2>
          {documentToReactComponents(info.theName)}
        </>
      )
    }
    const renderPlaylist = x => {
      return {__html: x};
    }

    const info = props.wine && props.wine.fields
    const art = info.wineArt && info.wineArt.fields.file.url
  const title = info.wineArt && info.wineArt.fields.title
    return (
      <>
        <div
          className="back-to-our-wines inner-container m-auto"
          onClick={() => history.push("/our-wines")}>
          go back to&nbsp;<span className="fw-bold">Our Wines</span>
        </div>
        <div className="container m-b-200 p-t-100 wine-static">
          <div className="d-flex align-items-center flex-column flex-md-row">
            <img className="flex-1 wine-art"src={art} alt={title}/>
            <div className="m-l-0 m-t-50 m-md-l-50 m-b-100 flex-1">
              <h1 className="huge-text-no-quote m-b-20">{info.wineName}</h1>
              <img className="width-125 m-t-30 m-b-20" src={Line} alt=""/>
              <p>{info.description}</p>
              <p className="i">{info.keywords ? `Keywords: ${info.keywords.map(x => x).join(", ")}` : null}</p>
              <div>
                <Button
                  small
                  type="brush"
                  target="_blank"
                  href="https://auburn-road-vineyard-winery-enoteca.square.site/">Buy now
                </Button>
              </div>
            </div>
          </div>
          
          <div className="gen-info">
            <div>
              <div className="m-b-50 m-t-50">{info.theGrape && renderGrape()}</div>
              <div className="m-b-50">{info.theWine && renderWine()}</div>
              <div className="m-b-50">{info.theName && renderName()}</div>
              {info.playlists && <h2 className="m-b-20">Playlist{info.playlists.length > 1 && "s"}</h2>}
              {info.playlists && info.playlists.map((x, i) => {
                return (
                  <div className="m-b-20" dangerouslySetInnerHTML={renderPlaylist(x)} key={i}>
                  </div>
                )
              })}
            </div>
            

          </div>
        </div>
      </>
    )}

export default withRouter(WineStaticPage);
