import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CTA from "../components/CTA";
import WinesRow from "../images/wines-in-a-row.png";
import Sangria from "../images/sangria.png";
import SheWolf from "../images/corner/corner-shewolf.webp";
import Button from "../components/Button";
import Line from "../images/brush-hr.svg";
import { Link } from "react-scroll";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";
import moment from "moment";
import _ from "underscore";
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <img
        className="w-50 m-auto"
        src={node.data?.target?.fields?.file?.url}
        alt={node.data?.target?.fields?.title}
      />
    ),
  },
};

const WhatsHappening = (props) => {
  let resScript;
  useEffect(() => {
    resScript = document.createElement("script");
    resScript.innerHTML = `!function(t,o,c,k){if(!t.tock){var e=t.tock=function(){e.callMethod?
    e.callMethod.apply(e,arguments):e.queue.push(arguments)};t._tock||(t._tock=e),
    e.push=e,e.loaded=!0,e.version='1.0',e.queue=[];var f=o.createElement(c);f.async=!0,
    f.src=k;var g=o.getElementsByTagName(c)[0];g.parentNode.insertBefore(f,g)}}(
    window,document,'script','https://www.exploretock.com/tock.js');

    tock('init', 'auburnroadvineyards');`;
    resScript.async = true;
    document.head.appendChild(resScript);
    return () => resScript.remove();
    document.title = "Auburn Road - What's Happening";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);
  const history = useHistory();
  const { info } = props;
  const renderPerformances =
    info &&
    info.fields.performance.map((item, i) => {
      return (
        <div className="m-b-10" key={i}>
          <span className="performance-date m-b-0 ">
            {moment(item.key).format("dddd, MMMM Do")}:
          </span>
          <br />
          <span className="performance-event">{item.value}</span>
        </div>
      );
    });
  const renderPlaylist = (x) => {
    return { __html: x };
  };
  const path =
    info.fields.wineStory.fields.uniqueUrl ||
    info.fields.wineStory.fields.title.split(" ").join("-");
  const title = info.fields.wineStory.fields.title;
  const storyImage = info.fields.wineStory.fields.storyImage.fields.file.url;
  if (!info) return null;
  return (
    <>
      <img className="corner-shewolf" src={SheWolf} alt="" />
      <div className="container p-t-100 m-b-50">
        <div className="d-flex flex-column d-inline-block align-items-center m-b-100">
          <h1 className="huge-text-no-quote">What's happening</h1>
          <span className="at-the-enoteca">at the Enoteca</span>
        </div>
        <div className="container inner-container">
          <p className="hide-mobile">
            We are beyond excited to welcome you to Auburn Road! When you
            arrive, follow the signs to where our host will greet you and guide
            you to your table and your server will take care of everything else.
            Then, sit back, relax, and enjoy…you have certainly earned it.
          </p>
          <div className="d-flex flex-wrap justify-content-around m-b-50 smooth-links">
            <Link
              className="button button__secondary pointer"
              activeClass="active"
              to="live"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Performances
            </Link>
            <Link
              className="button button__secondary pointer"
              activeClass="active"
              to="wine-story"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Wine Story
            </Link>
            {!_.isEmpty(info.fields.newReleases) && (
              <Link
                className="button button__secondary pointer"
                activeClass="active"
                to="new-releases"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                New Releases
              </Link>
            )}
            {!_.isEmpty(info.fields.excitingNews) && (
              <Link
                className="button button__secondary pointer"
                activeClass="active"
                to="exciting-news"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Exciting News!
              </Link>
            )}
            {!_.isEmpty(info.fields.niceThings) && (
              <Link
                className="button button__secondary pointer"
                activeClass="active"
                to="nice-things"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Nice Things
              </Link>
            )}
            {!_.isEmpty(info.fields.playlistWeek) && (
              <Link
                className="button button__secondary pointer"
                activeClass="active"
                to="playlist"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Playlist
              </Link>
            )}
            {!_.isEmpty(info.fields.otherStuff) && (
              <Link
                className="button button__secondary pointer"
                activeClass="active"
                to="other-stuff"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Other Stuff
              </Link>
            )}
          </div>
        </div>
        <div name="live" className="inner-container m-auto text-start">
          <h2 className="huge-text-no-quote text-center">Live!</h2>
          <h2 className="text-center m-b-30">from the vineyard stage</h2>
          <p>
            The Vineyard Stage at the Enoteca offers great music and
            performances all year long!  Ravello Woodfire creations available!
            Reservations recommended.
          </p>
          <p>
            {" "}
            <span className="fw-bold">LIVE! From the Vineyard Stage:</span>{" "}
            Every Saturday Night, we welcome amazing performers from the region,
            Philly and beyond.
          </p>
          <div className="d-flex flex-column flex-md-row align-items-center p-t-20 position-relative m-b-100">
            <div className="flex-1 m-r-30">
              <img className="height-300" src={Sangria} alt="sangria art" />
            </div>
            <div name="upcoming" className="m-t-40 m-md-t-0 flex-1 text-start">
              <h3>Upcoming Performances</h3>
              <img className=" width-100 m-t-0 m-b-15" src={Line} alt="" />
              {renderPerformances}
            </div>
          </div>
          <div
            className="m-b-20"
            id="Tock_widget_container"
            data-tock-display-mode="Inline"
            data-tock-color-mode="Blue"
            data-tock-locale="en-us"
            data-tock-timezone="America/New_York"
            data-tock-offering="452840"
          ></div>
          {!_.isEmpty(info.fields.upcomingEvents) ? (
            <div className="m-b-100 m-t-200 m-md-t-0">
              <h2 className="m-b-50 huge-text-no-quote text-center">
                Upcoming events
              </h2>
              <div className="text-start">
                {documentToReactComponents(info.fields.upcomingEvents, options)}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <CTA
        className="p-t-25 align-items-center p-b-50 flex-column flex-md-row"
        light
        blurb={
          <div className="m-l-30 m-t-20 m-md-t-0">
            <h4>Try the auburn road wine finder</h4>
            <p>
              Check out our tool to help your find the perfect wine (or wines)
              for you!
            </p>
            <Button onClick={() => history.push("/our-wines")} type="secondary">
              check it out
            </Button>
          </div>
        }
      >
        <div>
          <img
            className="m-auto width-350"
            src={WinesRow}
            alt="wines in a row"
          />
        </div>
      </CTA>
      <div className="inner-container m-auto">
        <div name="wine-story" className="m-b-100 m-t-100">
          <h4 className="text-center">this week's</h4>
          <h2 className="huge-text-no-quote m-b-30 text-center">Wine Story</h2>
          <div className="wine-story-box">
            <p className="title">"{title}"</p>
            <img className="w-100 m-b-30" src={storyImage} alt={title} />
            <div className="i">
              {documentToReactComponents(
                info.fields.wineStory.fields.blurb,
                options
              )}
            </div>

            <Button
              onClick={() => history.push(path)}
              className="continue-reading"
              small
              type="plain"
            >
              continue reading
            </Button>
          </div>
        </div>
        {!_.isEmpty(info.fields.newReleases) && (
          <div name="new-releases" className="m-b-100">
            <h2 className="huge-text-no-quote m-b-30 text-center">
              New releases
            </h2>
            <div className="text-start">
              {documentToReactComponents(info.fields.newReleases, options)}
            </div>
          </div>
        )}
        {!_.isEmpty(info.fields.excitingNews) && (
          <div name="exciting-news" className="m-b-100">
            <h2 className="huge-text-no-quote m-b-30 text-center">
              Exciting News
            </h2>
            <div className="text-start">
              {documentToReactComponents(info.fields.excitingNews, options)}
            </div>
          </div>
        )}
        {!_.isEmpty(info.fields.niceThings) && (
          <div name="nice-things" className="m-b-100">
            <h2 className="huge-text-no-quote m-b-30 text-center">
              Nice Things people say
            </h2>
            <div className="text-start">
              {documentToReactComponents(info.fields.niceThings, options)}
            </div>
          </div>
        )}
        {!_.isEmpty(info.fields.playlistWeek) && (
          <div name="playlist" className="m-b-100 m-t-150">
            <h2 className="huge-text-no-quote m-b-30 text-center">
              Playlist of the week
            </h2>
            {info.fields.playlistWeek.map((x, i) => {
              return (
                <div
                  className="m-b-20"
                  dangerouslySetInnerHTML={renderPlaylist(x)}
                  key={i}
                ></div>
              );
            })}
          </div>
        )}
        {!_.isEmpty(info.fields.otherStuff) && (
          <div name="other-stuff" className="m-b-100">
            <h2 className="huge-text-no-quote m-b-30 text-center">
              Other Stuff
            </h2>
            <div className="text-start">
              {documentToReactComponents(info.fields.otherStuff, options)}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default withRouter(WhatsHappening);
