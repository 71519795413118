import React from "react";
import { withRouter } from "react-router-dom";
import WineBlock from "../components/WineBlock";
import Line from "../images/brush-hr.svg";
import Rosalita from "../images/corner/corner-rosalita.webp";
import ReservationCTA from "../components/ReservationCTA";
import classnames from "classnames";
import _ from "underscore";
import KeywordBlock from "../components/KeywordBlock";

class OurWines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wineName: null,
      showWineModal: false,
      searchFilter: [],
      uncommonListSelected: false,
    };
  }

  componentDidMount() {
    document.title = "Auburn Road - Our Wines";
  }
  openWineModal = (wine) => {
    document.getElementById("body").classList.add("scroll-lock");
    this.setState({ wineName: wine });
  };
  hideWineModal = () => {
    this.setState({ wineName: null });
    document.getElementById("body").classList.remove("scroll-lock");
  };
  handleSearch = (e) => {
    const event = e.target.value;
    this.setState({
      searchFilter: event,
    });
  };

  wineSearch = (e) => {
    const selected = e.target.className === "wine-keyword--selected";
    if (selected) {
      const findWine = this.state.searchFilter.indexOf(e.target.outerText);
      const list = this.state.searchFilter;
      list.splice(findWine, 1);
      this.setState({ searchFilter: list });
      e.target.className = "wine-keyword";
    } else {
      this.setState({
        searchFilter: this.state.searchFilter.concat(e.target.outerText),
      });
      e.target.className = "wine-keyword--selected";
    }
  };

  render() {
    let data = this.props.state.filter((x) => {
      if (this.state.searchFilter) {
        return this.state.searchFilter.every(
          (r) => x.fields.keywords && x.fields.keywords.includes(r)
        );
      } else {
        return true;
      }
    });
    const commonKeyWords = [
      "white",
      "red",
      "rose",
      "port",
      "fruit",
      "dry",
      "semi-dry",
      "semi-sweet",
      "sweet",
      "light",
      "medium bodied",
      "medium-to-full bodied",
      "full bodied",
      "crisp",
      "oak",
      "sparkling",
    ];

    let whatsLeft = data
      .map((x) => x.fields.keywords)
      .reduce((accum, item) => {
        const lowerCaseItem = item ? item.map((x) => x.toLowerCase()) : null;
        return [...new Set(accum.concat(lowerCaseItem))].sort();
      }, []);
    const renderWineBlocks = data.map((wine) => {
      const w = wine.fields;
      return (
        <WineBlock
          wineInfo={w}
          key={w.wineName}
          openModal={() => this.openWineModal(wine)}
        />
      );
    });

    const keywordClick = (item) => {
      this.setState({ searchFilter: this.state.searchFilter.concat(item) });
      if (this.state.searchFilter.some((x) => x === item)) {
        const findWine = this.state.searchFilter.indexOf(item);
        const list = this.state.searchFilter;
        list.splice(findWine, 1);
        this.setState({ searchFilter: list });
      }
    };
    return (
      <>
        <div className="container p-b-100 p-t-75">
          <div className="text-sm-center">
            <div
              className={classnames("quote", {
                "hide-quote": !_.isEmpty(this.state.searchFilter),
              })}
            >
              <h1 className="huge-text-no-quote d-inline-block m-t-50 m-md-t-0">
                Our Wines
              </h1>
              <img className="width-100 m-auto m-t-50" src={Line} alt="" />
              <div className="wine-finder__header m-t-30 inner-container m-auto">
                <h2 className="m-b-10 text-center">
                  Try Auburn Road’s "WineFinder"!
                </h2>
                <p>Let us help you locate your perfect Auburn Road wine!</p>
              </div>
            </div>
          </div>
          <div className="wine-finder">
            <div className="d-flex justify-content-end flex-column align-items-end inner-container m-auto">
              <div className="d-flex flex-column flex-wrap m-b-50 align-self-start text-center m-auto w-100">
                <h4>Common keywords</h4>
                <p className=" inner-container m-auto m-b-20">
                  This is a list of some common keywords that describe our
                  wines. Select some keywords that describe wines you like and
                  we’ll help you find the wine (or wines…) that are just right
                  for you!
                </p>
                <div className="d-flex flex-wrap justify-content-center">
                  {whatsLeft.map((item, i) => {
                    if (!commonKeyWords.some((x) => x === item)) return null;
                    return (
                      <KeywordBlock
                        key={i}
                        selected={this.state.searchFilter.some(
                          (x) => x === item
                        )}
                        onClick={() => keywordClick(item)}
                      >
                        {item}
                      </KeywordBlock>
                    );
                  })}
                </div>
                <div
                  className="m-t-20 styled-link"
                  onClick={() => this.setState({ searchFilter: [] })}
                >
                  <span>clear all</span>
                </div>
              </div>
              {!_.isEmpty(this.state.searchFilter) && data.length > 1 && (
                <div className="d-flex flex-column align-self-start flex-wrap text-center m-auto">
                  <h4>Want to dig a little deeper?</h4>
                  <p>
                    This is a list of some more unique keywords that describe
                    our wines and
                    <br /> show how{" "}
                    <span className="fw-bold">everything we do is art.</span>
                    <a
                      className="m-l-10 pointer"
                      type="plain"
                      onClick={() => {
                        this.setState({
                          uncommonListSelected:
                            !this.state.uncommonListSelected,
                        });
                      }}
                    >
                      {!this.state.uncommonListSelected
                        ? "see the list"
                        : "close the list"}
                    </a>
                  </p>
                  <div className="d-flex flex-wrap justify-content-center">
                    {this.state.uncommonListSelected &&
                      !_.isEmpty(this.state.searchFilter) &&
                      whatsLeft.map((item, i) => {
                        if (commonKeyWords.some((x) => x === item)) return null;
                        if (item === (undefined || null)) {
                          return null;
                        }
                        return (
                          <KeywordBlock
                            key={i}
                            selected={this.state.searchFilter.some(
                              (x) => x === item
                            )}
                            onClick={() => keywordClick(item)}
                          >
                            {item}
                          </KeywordBlock>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
            <div className="wine-block-grid">
              {_.isEmpty(data) ? (
                <p>sorry, your search did not return any results</p>
              ) : (
                renderWineBlocks
              )}
            </div>
          </div>
        </div>
        <ReservationCTA />
        <img src={Rosalita} className="corner-rosalita" alt="" />
      </>
    );
  }
}

export default withRouter(OurWines);
