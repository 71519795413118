import React, { useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Ladybug from "../images/corner/corner-dragonfly.webp";
import Button from "../components/Button";

const EmailClub = (props) => {
  useEffect(() => {
    // Set the document title
    document.title = "Auburn Road - Email Club";

    // Optionally, you can return a cleanup function if needed
    return () => {
      // This will run when the component is unmounted or before the effect runs again
      document.title = "Auburn Road Vineyards";
    };
  }, []);
  const { info } = props;
  if (!info) return null;
  return (
    <>
      <img className="corner-dragonfly" src={Ladybug} alt="" />
      <div className="container inner-container p-t-100 m-b-100">
        <h2>Join the</h2>
        <h2 className="huge-text-no-quote m-b-75">AUBURN ROAD EMAIL CLUB!</h2>
        <div>
          <form
            className="m-b-50"
            action="https://squareup.com/outreach/tUfIZt/subscribe"
            method="POST"
            target="_blank"
          >
            <label className="w-100">Email</label>
            <input
              className="signup-input w-50"
              type="email"
              name="email_address"
              placeholder="your email address"
            />
            <input type="hidden" name="embed" value="true" />
            <Button className="brush-button mt-3" type="submit">
              sign up now
            </Button>
          </form>
          {documentToReactComponents(info[0].fields.mainContent)}
        </div>
      </div>
    </>
  );
};
export default EmailClub;
