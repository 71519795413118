import React, { useEffect } from "react";
import CornerLadybug from "../images/corner/corner-ladybug-art.webp";

const WhereToFind = () => {
  useEffect(() => {
    // Set the document title
    document.title = "Auburn Road - Where To Find Our Wines";

    // Optionally, you can return a cleanup function if needed
    return () => {
      // This will run when the component is unmounted or before the effect runs again
      document.title = "Auburn Road Vineyards";
    };
  }, []);

  return (
    <>
      <img className="corner-ladybug" src={CornerLadybug} alt="" />
      <div className="inner-container m-auto p-t-150 m-b-200">
        <h2 className="huge-text m-b-100">Where To Find Our Wines</h2>
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1ig3EuEM-XlzH39xsPaB8F_Pz8cM"
          width="100%"
          height="380"
          title="where to find our wines"
        ></iframe>
        <h3 className="m-t-100">NEW JERSEY</h3>
        <ul>
          <li>
            5 O&rsquo;Clock Wine &amp; Spirits &ndash; 173 Bridgeton Pike,
            Mullica Hill, NJ 08062
          </li>
          <li>
            A J&rsquo;s Tavern &ndash;&nbsp;114 N Broadway, Pennsville, NJ 08070
          </li>
          <li>
            Bobkat Liquors &ndash;&nbsp;1361 Delsea Dr, Deptford Township, NJ
            08096
          </li>
          <li>
            Bootleggers &ndash; 200 Tilton Road,&nbsp;Northfield, NJ 08225
          </li>
          <li>
            Bottlestop Wines &amp; Spirits &ndash; 56 Crescent Blvd., Gloucester
            City, NJ 08030
          </li>
          <li>
            Boulevard Super Liquors &ndash;&nbsp;501 Roosevelt Blvd, Marmora, NJ
            08223
          </li>
          <li>
            Bubba&rsquo;s Discount Liquors &ndash;&nbsp;1849 N Delsea Dr,
            Vineland, NJ 08360
          </li>
          <li>
            Buy-Rite Liquors Williamstown &ndash;&nbsp;2031 N Black Horse Pike,
            Williamstown, NJ 08094
          </li>
          <li>
            Canal&rsquo;s of Berlin &ndash;&nbsp;8 NJ Rte 73, Berlin, NJ 08009
          </li>
          <li>
            Canal&rsquo;s Discount Liquor Mart &ndash;&nbsp;1299 Delsea Dr,
            Glassboro, NJ 08028
          </li>
          <li>
            Canal&rsquo;s Discount Liquor Mart &ndash;&nbsp;10 W Rte 70,
            Marlton, NJ 08053
          </li>
          <li>
            Cherrywood Liquors &ndash;&nbsp;1279 Blackwood Clementon Rd,
            Clementon, NJ 08021
          </li>
          <li>
            Clayton Liquors &ndash;&nbsp;369 N Delsea Dr, Clayton, NJ 08312
          </li>
          <li>
            Colonial Liquor Store &ndash;&nbsp;215 S Virginia Ave, Penns Grove,
            NJ 08069
          </li>
          <li>
            Cramp&rsquo;s Liquor Store &ndash;&nbsp;4267 US-130, Edgewater Park,
            NJ 08010
          </li>
          <li>
            Deptford Liquor Mart &ndash; 1076 Delsea Drive, Westville, NJ 08093
          </li>
          <li>
            EZ Liquors &ndash;&nbsp;35 Route 37 East, Toms River, NJ 08753
          </li>
          <li>
            Fairfield Inn &amp; Suites by Marriott Mt. Laurel &ndash;&nbsp;350
            Century Pkwy, Mt Laurel, NJ 08054
          </li>
          <li>
            Fornaro&rsquo;s Liquor Store &ndash;&nbsp;175 N Broadway,
            Pennsville, NJ 08070
          </li>
          <li>
            G&rsquo;s Liquor Gallery &ndash;&nbsp;997 Kings Hwy, West Deptford,
            NJ 08086
          </li>
          <li>
            Haddon Culinary &ndash; 741 Haddon Ave., Collingswood, NJ 08108*
          </li>
          <li>
            Holy Tomato &ndash;&nbsp;9 S Black Horse Pike, Blackwood, NJ 08012*
          </li>
          <li>
            Hill Creek Farms &ndash;&nbsp;1631 NJ-45, Mullica Hill, NJ 08062*
          </li>
          <li>
            Hops &amp; Grapes &ndash;&nbsp;810 Delsea Dr, Glassboro, NJ 08028
          </li>
          <li>
            J B Liquor &ndash;&nbsp;120 Center Square Rd, Swedesboro, NJ 08085
          </li>
          <li>
            J Tee&rsquo;s Liquors Wines &amp; Beer &ndash;&nbsp;641 S Broadway,
            Pennsville, NJ 08070
          </li>
          <li>
            Joe Canal&rsquo;s Discount Liquor Bellmawr &ndash; 15 N Black Horse
            Pike, Bellmawr, NJ 08031
          </li>
          <li>
            Joe Canal&rsquo;s Discount Liquor Outlet Millville &ndash;&nbsp;2213
            N 2nd St, Millville, NJ 08332
          </li>
          <li>
            Kenny&rsquo;s Liquor &ndash;&nbsp;414 S Delsea Dr, Clayton, NJ 08312
          </li>
          <li>
            King&rsquo;s Liquor Outlet &ndash;&nbsp;542 Cross Keys Rd,
            Sicklerville, NJ 08081
          </li>
          <li>Liquor Mart &ndash;&nbsp;554 S Delsea Dr, Vineland, NJ 08360</li>
          <li>
            Liquor Mart Logan Township &ndash;&nbsp;2640 US-322, Logan Township,
            NJ 08085
          </li>
          <li>
            Liquor Mill &ndash;&nbsp;200 S White Horse Pike, Magnolia, NJ 08049
          </li>
          <li>
            Liquor Xpress &ndash; 3600 E Landis Ave #5, Vineland, NJ 08361
          </li>
          <li>Liquor Xpress &ndash;&nbsp;67 E Oak Rd, Vineland, NJ 08360</li>
          <li>
            Martin&rsquo;s Liquors &ndash;&nbsp;3601 NJ-38, Mt Laurel, NJ 08054
          </li>
          <li>
            Original Canal&rsquo;s Discount Liquor Mart &ndash;&nbsp;717 N Pearl
            St, Bridgeton, NJ 08302
          </li>
          <li>
            Passion Vines Wine Bar &ndash;&nbsp;265 New Rd, Somers Point, NJ
            08244
          </li>
          <li>
            Passion Vines Wine &amp; Spirit Company &ndash;&nbsp;3013 Ocean
            Heights Ave, Egg Harbor Twp, NJ 08234
          </li>
          <li>
            Rastelli&rsquo;s Market Fresh* &ndash; 1276 Clements Bridge Road,
            Deptford, NJ 08096
          </li>
          <li>
            Rastelli&rsquo;s Market Fresh &ndash; 710 Route 73 S, Marlton, NJ
            08053
          </li>
          <li>
            Roger Wilco Discount Liquor &ndash;&nbsp;1345 Hurffville Rd,
            Deptford Township, NJ 08096
          </li>
          <li>
            ShopRite Wines &amp; Spirits &ndash;&nbsp;141 US-130, Cinnaminson,
            NJ 08077
          </li>
          <li>
            ShopRite Wines &amp; Spirits &ndash;&nbsp;382 Egg Harbor Rd, Sewell,
            NJ 08080
          </li>
          <li>
            ShopRite Wine &amp; Spirits &mdash;&nbsp;1255 W Landis Ave Suite 2,
            Vineland, NJ 08360
          </li>
          <li>
            Spirits Warehouse &ndash;&nbsp;415 S Evergreen Ave, Woodbury, NJ
            08096
          </li>
          <li>
            Super Buy Rite Liquors &ndash;&nbsp;1075 Mantua Pike, West Deptford,
            NJ 08096
          </li>
          <li>
            Total Wine &amp; More &ndash;&nbsp;2100 NJ-38, Cherry Hill, NJ 08002
          </li>
          <li>
            TownePlace Suites by Marriott &ndash;&nbsp;3 Pureland Dr,
            Swedesboro, NJ 08085
          </li>
          <li>
            Turnpike Inn &ndash;&nbsp;601 S Pennsville Auburn Rd, Carneys Point,
            NJ 08069
          </li>
          <li>Villa Barone &ndash; 753 Haddon Ave, Collingswood, NJ 08108*</li>
          <li>
            Vineland Discount Liquor &ndash; 1668 S Delsea Dr, Vineland, NJ
            08360
          </li>
          <li>
            Wine Warehouse of Gloucester Twp &ndash;&nbsp;1460 Blackwood
            Clementon Rd, Clementon, NJ 08021
          </li>
          <li>
            Wine Warehouse of Mantua &ndash;&nbsp;222 Bridgeton Pike, Mantua
            Township, NJ 08051
          </li>
          <li>
            Wine Warehouse of Sicklerville &ndash;&nbsp;665 Cross Keys Rd,
            Sicklerville, NJ 08081
          </li>
          <li>
            Wine Warehouse of Voorhees &ndash; 700 Haddonfield-Berlin Rd #38a,
            Voorhees Township, NJ 08043
          </li>
          <li>Wineworks &ndash;&nbsp;319 W Rte 70, Marlton, NJ 08053</li>
          <li>
            Woodstown Tavern Hotel &ndash; 13 N Main St, Woodstown, NJ 08098
          </li>
          <li>Lyberry Coffee -3 Old Trenton Rd, Cranbury, NJ 08512 *</li>
          <li>Nine Thai - 7 S Broadway, Pitman, NJ 08071 *</li>
          <li>Spicy Affair -1427 Kings Hwy, Swedesboro, NJ 08085 *</li>
          <li>
            In Dietro caffe - Behind La Faccia Bella, 22 S Main St, Medford, NJ
            08055 *
          </li>
          <li>Tavola Rustica - 243 N Union St, Lambertville, NJ 08530 *</li>
          <li>Hens & Honey -95 Woodstown Rd, Swedesboro, NJ 08085 *</li>
          <li>
            Boaggio’s Bread - 4201 Church Rd Unit 1 & 2, Mt Laurel Township, NJ
            08054 *
          </li>
        </ul>
        <h3>PENNSYLVANIA</h3>
        <ul>
          <li>Good Karma Cafe - 928 Pine St, Philadelphia, PA 19107 *</li>
          <li>Yardley General &ndash; 14 S. Main Street, Yardley, PA 19067</li>
        </ul>
        <h3>DELAWARE</h3>
        <ul>
          <li>
            Beaver Brook Liquors &ndash; 550 S Dupont Hwy, New Castle, DE 19720
          </li>
          <li>
            Concord Pike Liquors &ndash; 4013 Concord Pike, Wilmington, DE 19809
          </li>
          <li>
            Frank&rsquo;s Wine &ndash; 1206 N Union St, Wilmington, DE 19806
          </li>
          <li>
            Peco&rsquo;s Liquors -522 Philadelphia Pike, Wilmington, DE 19803
          </li>
          <li>
            Wilmington Wine Company &ndash; 510 Greenhill Ave, Wilmington, DE
            19805
          </li>
        </ul>
        <h3>MARYLAND</h3>
        <ul>
          <li>
            State Line Liquors &ndash;&nbsp;1610 Elkton Rd, Elkton, MD 21921
          </li>
          <li>
            Weaver&rsquo;s Discount Liquors &ndash;&nbsp;1747 W Pulaski Hwy,
            Elkton, MD 21921
          </li>
        </ul>
        <p>*denotes one of our wine outlets</p>
      </div>
    </>
  );
};

export default WhereToFind;
