import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

export const paths = [
  { path: "/", text: "Home", section: "main", external: false },
  {
    path: "/whats-happening",
    text: "What's Happening",
    section: "main",
    external: false,
  },
  { path: "/our-wines", text: "Our Wines", section: "main", external: false },
  {
    path: "/the-enoteca",
    text: "The Enoteca",
    section: "main",
    external: false,
  },
  { path: "/menu", text: "Menu", section: "main", external: false },
  {
    path: "/auburn-road-email-club",
    text: "Email Club",
    section: "main",
    external: false,
  },
  {
    path: "/auburn-road-csa",
    text: "Wine CSA",
    section: "main",
    external: false,
  },
  { path: "/faq", text: "FAQ's", section: "extras", external: false },
  {
    path: "/wine-stories",
    text: "Wine Stories",
    section: "extras",
    external: false,
  },
  {
    path: "/goodkarmacafephilly",
    text: "Good Karma Cafe Philly",
    section: "main",
    external: false,
  },
  {
    path: "/where-to-find-our-wines",
    text: "Where To Find Our Wines",
    section: "extras",
    external: false,
  },
  {
    path: "/the-winemakers",
    text: "The Winemakers",
    section: "extras",
    external: false,
  },
  {
    path: "/cowtown",
    text: "Cowtown Covered Wagon Tours",
    section: "extras",
    external: false,
  },
  {
    path: "/in-the-press",
    text: "In The Press",
    section: "extras",
    external: false,
  },
  {
    path: "/private-events",
    text: "Private Events",
    section: "extras",
    external: false,
  },
  {
    path: "/spirit-animal",
    text: "Our Spirit Animal Brand",
    section: "extras",
    external: false,
  },
  {
    path: "/giving-back-to-the-community",
    text: "Auburn Road Gives Back",
    section: "extras",
    external: false,
  },
  {
    path: "/wine-playlists",
    text: "Spotify Playlists",
    section: "extras",
    external: false,
  },
  {
    path: "/sustainability",
    text: "Sustainability",
    section: "extras",
    external: false,
  },
  //  { path: "/gallery", text: "Gallery", section: "extras", external: false },
  {
    path: "https://twobridgestrail.com/",
    text: "Two Bridges Wine Trail",
    section: "extras",
    external: true,
  },
  {
    path: "https://www.newjerseywines.com/",
    text: "Garden State Wine Growers Assn.",
    section: "extras",
    external: true,
  },
  {
    path: "https://visitsouthjersey.com/",
    text: "Visit South Jersey",
    section: "extras",
    external: true,
  },
  {
    path: "https://outercoastalplain.com/",
    text: "Outer Coastal Plain Vineyard Assn",
    section: "extras",
    external: true,
  },
  {
    path: "https://thewinemakersco-op.com/",
    text: "The Winemakers Co-Op",
    section: "extras",
    external: true,
  },
  {
    path: "https://drive.google.com/file/d/1oeuPzoC7URpRgp9PHdFe_at7E5T7lX9_/view",
    text: "I Want To Work Here",
    section: "extras",
    external: true,
  },
];

export const MenuLinks = ({
  currentPage,
  toggleContactOverlayFn,
  closeMenu,
}) => {
  const contactClick = () => {
    toggleContactOverlayFn();
    closeMenu();
  };
  let extras = [];
  let mains = [];
  paths.map((item, i) => {
    const { path, text, section, external } = item;
    const props = {
      external,
      ...(external ? { href: path } : { to: path }),
      onClick: closeMenu,
      className: classnames("link", {
        "link-selected": currentPage === path,
        "outgoing-link": external,
      }),
      children: text,
    };
    if (section === "main") {
      mains.push(<AOrLink key={i} {...props} />);
    } else {
      extras.push(<AOrLink key={i} {...props} />);
    }
  });

  return (
    <>
      {mains}
      <h3 className="m-t-25">Extras</h3>
      <div onClick={contactClick} className="link">
        Contact Info
      </div>
      {extras}
    </>
  );
};

export const AOrLink = (props) => {
  if (props.external) {
    return <a rel="noopener noreferrer" target="_blank" {...props} />;
  } else {
    return <Link {...props} />;
  }
};
