import React from 'react';
import classnames from 'classnames';

function Button(props) {
  const AOrButton = props.href ? "a" : "button"
  const buttonMap = {
    BRUSH: "brush",
    SECONDARY: "secondary",
    PLAIN: "plain"
  }
  return (
    <AOrButton
      id={props.id}
      className={
        classnames("button", props.className,
                   {"brush-button": props.type === buttonMap.BRUSH,
                    "button__secondary": props.type === buttonMap.SECONDARY,
                    "plain-button": props.type === buttonMap.PLAIN,
                    "small": props.small,
                   "light": props.light}
                  )}
      onClick={props.onClick}
      href={props.href}
      rel={props.href ? "noreferrer noopener" : null}
      target={props.href && "_blank"}
    >{props.children}
    </AOrButton>
  )
}

export default Button;
