import React, { useEffect } from "react";
import WineBehindGreenery from "../images/wine-behind-greenery.png";
import WineFlights from "../images/wine-flights.png";
import Button from "../components/Button";
import OpenTable, { positions } from "react-opentable";
import Flatbread from "../images/squares/flatbread.webp";
import Cheeseboard from "../images/squares/olives.webp";
import Chocolate from "../images/squares/chocolate.webp";
import Flights from "../images/squares/flights.webp";
import Affogato from "../images/squares/affogato.webp";
import Fence from "../images/squares/fence.webp";
import Latte from "../images/squares/latte.webp";
import ThreeWines from "../images/squares/three-wines.webp";
import Wave from "../images/waves/wave-light.svg";
import WhiteBottle from "../images/squares/white-bottle.webp";
import WinesRow from "../images/wines-row.jpg";

const TheEnoteca = (props) => {
  useEffect(() => {
    // Set the document title
    document.title = "Auburn Road - The Enoteca";

    // Optionally, you can return a cleanup function if needed
    return () => {
      // This will run when the component is unmounted or before the effect runs again
      document.title = "Auburn Road Vineyards";
    };
  }, []);

  let resScript;
  useEffect(() => {
    resScript = document.createElement("script");
    resScript.innerHTML = `!function(t,o,c,k){if(!t.tock){var e=t.tock=function(){e.callMethod?
    e.callMethod.apply(e,arguments):e.queue.push(arguments)};t._tock||(t._tock=e),
    e.push=e,e.loaded=!0,e.version='1.0',e.queue=[];var f=o.createElement(c);f.async=!0,
    f.src=k;var g=o.getElementsByTagName(c)[0];g.parentNode.insertBefore(f,g)}}(
    window,document,'script','https://www.exploretock.com/tock.js');

    tock('init', 'auburnroadvineyards');`;
    resScript.async = true;
    document.head.appendChild(resScript);
    return () => resScript.remove();
  }, []);
  return (
    <>
      <img
        src={WineBehindGreenery}
        className="corner-wine-behind-greenery"
        alt="corner-wine-behind-greenery"
      />
      <div className="container p-t-150 m-b-150">
        <h1 className="huge-text-no-quote">
          European style
          <br /> vineyard <span className="accent-text">Wine bar</span>
        </h1>
        <h3 className="subheading m-t-20">Welcome to the enoteca</h3>
        <p className="inner-container">
          ...our European-style Vineyard Wine Bar, Patio and Courtyard
          surrounded by the vines – where we combine a cool vibe and
          down-to-earth attitude with excellent wine. Let us seat you at your
          table and have our wonderful team of servers take good care of you.
        </p>
        <iframe
          width="560"
          height="315"
          style={{ maxWidth: "100%", margin: "20px 0" }}
          src="https://www.youtube.com/embed/wqIzi8XbSqs"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div
          id="Tock_widget_container"
          data-tock-display-mode="Inline"
          data-tock-color-mode="Blue"
          data-tock-locale="en-us"
          data-tock-timezone="America/New_York"
          data-tock-offering="452840"
        ></div>
      </div>
      <div className="enoteca-section relax">
        <img src={Wave} className="position-absolute" alt="" />
        <div className="container">
          <h1 className="huge-text-no-quote">Relax at the enoteca</h1>
          <p>
            …with a glass of wine and try our wood fire pizza or flatbreads, or
            our Buddha Baskets full of all kinds of delicious artisan goodies
          </p>
        </div>
      </div>
      <div className="d-flex square-wrap inner-container m-auto">
        <img className="square" src={Cheeseboard} alt="cheese board" />
        <img className="square" src={Flatbread} alt="flatbread" />
        <img className="square" src={ThreeWines} alt="three wines" />
      </div>
      <div className="container d-flex flex-column flex-md-row p-t-100 justify-content-between align-items-centers">
        <div>
          <img
            className="flight-img w-100"
            src={WineFlights}
            alt="wine flights"
          />
        </div>
        <div className="m-l-40 m-lg-l-0 m-t-30 m-md-t-0">
          <h1 className="huge-text-no-quote">
            ...Or start <br />
            with a flight
          </h1>
          <p className="m-t-30">
            if you want to do a self-guided tasting to see what feels right
            today…
          </p>
          <Button
            id="flights-reservation-click"
            type="secondary"
            onClick={() =>
              window.open(
                "https://www.opentable.com/r/auburn-road-vineyard-winery-enoteca-pilesgrove",
                "_blank"
              )
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            Make a reservation
          </Button>
        </div>
      </div>
      <div className="enoteca-section m-t-150 music">
        <div className="container p-md-t-150">
          <h1 className="huge-text-no-quote text-center">
            stay for some music
          </h1>
          <p className="text-center m-auto">…and hang out by the vineyards</p>
        </div>
      </div>
      <div className="d-flex square-wrap inner-container m-auto p-b-200">
        <img className="square" src={Fence} alt="auburn road fence" />
        <img className="square" src={Flights} alt="wine flights" />
        <img
          className="square"
          src={WhiteBottle}
          alt="white bottle - auburn road wine"
        />
      </div>

      <div className="enoteca-section brighten m-t-50 m-md-t-0">
        <img src={Wave} className="position-absolute" alt="" />
        <div className="container">
          <h1 className="huge-text-no-quote">...and then maybe</h1>
          <p>something to brighten you up...</p>
        </div>
      </div>
      <div className="d-flex square-wrap inner-container m-auto">
        <img className="square" src={Affogato} alt="affogato" />
        <img className="square" src={Latte} alt="latte" />
        <img className="square" src={Chocolate} alt="chocolate bars" />
      </div>
      <div className="inner-container m-auto text-center m-b-200 p-t-100">
        <p className="m-b-0">...and don't forget to</p>
        <h1 className="huge-text-no-quote">
          bring a little auburn road home with you
        </h1>
        <img
          className="w-100 m-t-75 flight-img"
          src={WinesRow}
          alt="wines in a row"
        />
      </div>
    </>
  );
};

export default TheEnoteca;
