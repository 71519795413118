import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Flowers from "../images/flower-left.png";
import GoodKarma from "../images/corner/corner-good-karma.webp";

function AuburnRoadCSA(props) {
  useEffect(() => {
    document.title = "Auburn Road - CSA";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);
  return (
    <>
      <img className="csa-flowers" src={Flowers} alt="" />
      <div className="container p-t-150 m-b-100 inner-container arv-csa">
        <div className="m-b-75">
          <h1 className="huge-text-no-quote m-b-10">Auburn Road CSA</h1>
          <h3 className="subheading">What is a CSA?</h3>
          <p>
            Traditionally, a CSA (Community Supported Agriculture) program
            serves to create a community that celebrates local, sustainable,
            small-parcel agriculture and directly connects the farmer (which is
            also, in this case, a winemaker) with that community.
          </p>
        </div>
        {documentToReactComponents(props.info && props.info[0].fields.mainInfo)}
        {documentToReactComponents(
          props.info && props.info[0].fields.howToJoin
        )}
      </div>
    </>
  );
}

export function SustainingMember(props) {
  return (
    <>
      <img className="corner-good-karma" src={GoodKarma} alt="" />
      <div className="inner-container m-auto p-t-150 m-b-200">
        <h2 className="huge-text-no-quote m-b-40">
          How do i become a sustaining member?
        </h2>
        <div id="sustaining-member">
          {documentToReactComponents(
            props.info && props.info[0].fields.sustainingMember
          )}
        </div>
      </div>
    </>
  );
}
export default withRouter(AuburnRoadCSA);
