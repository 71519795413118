import React from 'react';
import classnames from 'classnames';
import Logo from '../images/logo-dark-css-animation.svg';
import { CSSTransitionGroup } from 'react-transition-group' // ES6

const FullPageLoader = ({ hide }) => {
  return (
    <div className={classnames("full-page-loader", {"d-none" : hide})}>
      <CSSTransitionGroup
        transitionName="fpl"
        transitionAppear={true}
        transitionAppearTimeout={5000}
        transitionEnter={false}
        transitionLeave={false}>
        <img className="fpl-image" src={Logo} alt=""/>
      </CSSTransitionGroup>
    </div>
  )
}

export default FullPageLoader;
