import React, { useState, useEffect } from "react";
import _ from "underscore";
import Hummingbird from "../images/corner/corner-hummingbird.webp";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS } from "@contentful/rich-text-types";
import Fuse from "fuse.js";

const FAQ = (props) => {
  useEffect(() => {
    document.title = "Auburn Road - FAQ";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);
  const [searchString, setSearchString] = useState("");
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
    },
  };
  const FaqBlock = (props) => {
    return (
      <li>
        <h3 className="question">{props.question}</h3>
        <div className="answer">{props.answer}</div>
      </li>
    );
  };
  const reduced = props.data.reduce((accum, qa, i) => {
    return [
      ...accum,
      {
        ["question"]: qa.fields.question,
        ["answer"]: qa.fields.answer,
      },
    ];
  }, []);
  const fuse = new Fuse(reduced, {
    threshold: 0.7,
    ignoreLocation: true,
    keys: ["question"],
  });

  const faqs = _.isEmpty(searchString)
    ? reduced
    : fuse.search(searchString).map((x) => x.item);
  return (
    <>
      <img className="corner-hummingbird" src={Hummingbird} alt="" />
      <div className="container p-t-100">
        <h1 className="huge-text-no-quote mb-3">FAQ's</h1>
        <h3>Looking for something specific?</h3>
        <p>Search for your question here.</p>
        <input
          className="email-input"
          onChange={(e) => setSearchString(e.target.value)}
          placeholder="type your question here"
        />
        <ul className="ul m-t-100">
          {!_.isEmpty(faqs) ? (
            faqs.map((x, i) => (
              <FaqBlock
                key={i}
                question={x.question}
                answer={documentToReactComponents(x.answer, options)}
              />
            ))
          ) : (
            <p>your search did not return any results</p>
          )}
        </ul>
      </div>
    </>
  );
};
export default FAQ;
