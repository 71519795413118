import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

const NutriIngredRouter = ({
  wines,
  parentPath,
  match: {
    params: { wine },
  },
}) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <img
          className="w-50 m-auto"
          src={node.data?.target?.fields?.file?.url}
          alt={node.data?.target?.fields?.title}
        />
      ),
    },
  };

  const wineName = wines.filter((x) => {
    const name = x.fields.wineName.split(" ").join("-").toLowerCase();
    return name === wine;
  });

  const Page = ({ ingredients, nutrition }) => {
    const name = wine.split("-").join(" ");
    return (
      <div>
        <div className="text-center m-b-100">
          <h3>Nutrition and Ingredient Information for</h3>
          <h1 className="huge-text-no-quote">{name}</h1>
        </div>
        <div>
          <h3>Ingredients:</h3>
          {ingredients}
        </div>
        <div>
          <h3>Nutrition Facts:</h3>
          {nutrition}
        </div>
      </div>
    );
  };
  return (
    <div className="inner-container m-auto p-t-150 p-b-150">
      <Switch>
        <Route
          path={`/nutrition-ingredients/${wine}`}
          render={() => (
            <Page
              ingredients={documentToReactComponents(
                wineName[0].fields.ingredients
              )}
              nutrition={documentToReactComponents(
                wineName[0].fields.nutritionFacts
              )}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default withRouter(NutriIngredRouter);
