import React from 'react';

export default class KeywordBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    }
  }
  render() {
    const {children, onClick, selected} = this.props;
    return (
      <div
        onClick={onClick}
        className={selected ? "wine-keyword--selected" : "wine-keyword" }>{children}</div>
    )
  }
}

