import classnames from 'classnames'

export default function CTA(props) {
  if (!props.reverse) {
  return (
    <div className={classnames("cta", {
      "light": props.light
    })}>
      <div className={classnames(props.className, "container d-flex" )}>
        <div className={`flex-${props.flexLeft || "1"}`}>{props.children}</div>
        <div className={`flex-${props.flexRight || "1"}`}>
          {props.src && <img src={props.src} alt=""/>}
          {props.blurb && props.blurb}
        </div>
      </div>
    </div>
  )
} else {
  return (
    <div className={classnames("cta", {
      "light": props.light
    })}>
      <div className={classnames(props.className, "container d-flex" )}>
        <div className={`flex-${props.flexRight || "1"}`}>
          {props.src && <img src={props.src} alt=""/>}
          {props.blurb && props.blurb}
        </div>
        <div className={`flex-${props.flexLeft || "1"}`}>{props.children}</div>
      </div>
    </div>

  )
}
  }
