import React from 'react';
import { withRouter } from "react-router-dom";

const UnderConstruction = (props) => {
    return (
      <div className="container text-center p-t-100">
        <h1 className="huge-text-no-quote">this page is Under Construction</h1>
      </div>
      )
}

export default withRouter(UnderConstruction);
