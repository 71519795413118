import React, { useEffect } from "react";
import GKLogo from "../images/good-karma-logo.png";

const GoodKarmaCafe = () => {
  useEffect(() => {
    // Set the document title
    document.title = "Auburn Road - Good Karma";

    // Optionally, you can return a cleanup function if needed
    return () => {
      // This will run when the component is unmounted or before the effect runs again
      document.title = "Auburn Road Vineyards";
    };
  }, []);
  return (
    <div className="container inner-container p-t-200">
      <h2 className="huge-text-no-quote">Good Karma Cafe</h2>
      <p className="mt-5">
        Auburn Road Vineyards would like to welcome you to Good Karma Cafe and
        Wine Bar Philly! Good Karma Café is a café and wine bar in the European
        style. Join us for world-class wine, locally made beer and spirits,
        amazing food and delicious organic and fair trade coffee! Two great
        locations in Society Hill and at the Wilma Theater on the Avenue of the
        Arts!
      </p>
      <p>
        <a href="https://www.thegoodkarmacafe.com" target="_blank">
          Good Karma Cafe & Wine Bar Society Hill 928 pine street Philly
        </a>
      </p>
      <p>
        <a>
          Good Karma Cafe & Wine Bar at the Wilma Theater - 265 Broad Street,
          Avenue of the Arts, Philly
        </a>
      </p>
      <img src={GKLogo} />
    </div>
  );
};
export default GoodKarmaCafe;
