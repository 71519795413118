import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";
import Line from "../images/brush-hr.svg";
import moment from "moment";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <img
          className="w-75 m-auto"
          src={node.data?.target?.fields?.file?.url}
          alt={node.data?.target?.fields?.title}
        />
      );
    },
  },
};

const WineStoryPage = (props) => {
  useEffect(() => {
    document.title = "Auburn Road - Wine Story - " + props.story.fields.title;
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);
  const image = props.story.fields.storyImage
    ? props.story.fields.storyImage.fields.file.url
    : null;
  const title = props.story.fields.storyImage
    ? props.story.fields.storyImage.fields.title
    : null;
  const nextPostTitle = props.nextPost ? props.nextPost.title : null;
  const nextPostTitleLink = props.nextPost
    ? props.nextPost.uniqueUrl ||
      props.nextPost.title.split(" ").join("-").toLowerCase()
    : null;
  const previousPostTitleLink = props.previousPost
    ? props.previousPost.uniqueUrl ||
      props.previousPost.title.split(" ").join("-").toLowerCase()
    : "last post";
  const previousPostTitle = props.previousPost
    ? props.previousPost.title
    : "last post";
  const renderNextPost = (
    <p>
      read the next wine story
      <br /> <Link to={`/${nextPostTitleLink}`}>{nextPostTitle}</Link>
    </p>
  );
  const renderPreviousPost = (
    <p>
      read the previous wine story
      <br />
      <Link to={`/${previousPostTitleLink}`}>{previousPostTitle}</Link>
    </p>
  );
  return (
    <div className="container m-b-100">
      <div className="wine-story-content">
        <h2 className="huge-text-no-quote m-b-50">
          {props.story.fields.title}
        </h2>
        <p>
          <Link to="/wine-stories">Go back to wine stories</Link>
        </p>
        {image && (
          <img className="story-page-image m-auto " src={image} alt={title} />
        )}
        <p className="mt-5 m-b-50 text-center">
          <span className="fw-bold">Date Posted: </span>
          <span className="i">
            {moment(props.story.fields.publishDate).format("MMMM Do, YYYY") ||
              moment(props.story.sys.createdAt).format("MMMM Do, YYYY")}
          </span>
        </p>
        <img className="width-150 m-auto m-b-50" src={Line} alt="" />
        {documentToReactComponents(props.story.fields.content, options)}
      </div>
      <div className="d-flex">
        <div className="previous-story flex-5 d-flex align-items-center">
          {props.previousPost ? (
            renderPreviousPost
          ) : (
            <Link to="/wine-stories">back to wine stories</Link>
          )}
        </div>
        <div className="flex-1 d-flex align-items-center"></div>
        <div className="next-story flex-5">
          {props.nextPost ? (
            renderNextPost
          ) : (
            <Link to="/wine-stories">back to wine stories</Link>
          )}
        </div>
      </div>
      <p className="text-center mt-3">
        <Link to="/wine-stories">Go back to wine stories</Link>
      </p>
    </div>
  );
};

export default WineStoryPage;
