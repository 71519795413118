import React, { useEffect } from 'react';

const Gallery = () => {
  useEffect(() => {
    // Update the document title using the browser API
    const script = document.createElement("script");

    script.src = "https://cdn.lightwidget.com/widgets/lightwidget.js";
    script.async = true;

    document.body.appendChild(script);
  });
  return (
    <div className="container p-t-100">
    <iframe src="https://cdn.lightwidget.com/widgets/54570f08e56154a5988f188418f9bf9f.html" scrolling="no" allowtransparency="true" className="lightwidget-widget" style={{width: '100%', border: '0', overflow: 'hidden'}}></iframe>
    </div>
    )
}


export default Gallery
