import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";

export class ContactOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const script = document.createElement("script");

    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;

    document.body.appendChild(script);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.props.toggleContactOverlay();
    }
  }

  render() {
    const { toggleContactOverlay, hours } = this.props;
    const windowWidth = window.innerWidth > 699;
    return (
      <div className="contact-background" id="contact-background">
        <div
          ref={this.wrapperRef}
          className="contact-wrap"
          id="contact-overlay"
        >
          <div className="contact-art"></div>
          <div className="contact-content">
            <div
              className="contact-close-icon"
              onClick={toggleContactOverlay}
            />
            <h1 className="contact-header m-l-30">Contact</h1>
            <div className="contact-content-wrap">
              <div className="contact-form-wrap">
                {windowWidth ? (
                  <div>
                    <p>
                      Cant find the answer here? Use the form below to get it
                      touch!
                    </p>
                    <p>
                      <a href="mailto:info@auburnroadvineyards.com">
                        info@auburnroadvineyards.com
                      </a>
                    </p>
                    <div
                      className="m-b-50"
                      data-tf-widget="t3vHk1tl"
                      data-tf-opacity="0"
                      data-tf-hide-headers
                      data-tf-hide-footer
                      style={{
                        width: "100%",
                        height: "400px",
                        color: "#FFFFFF",
                        background: "#fffdf7",
                      }}
                    ></div>
                  </div>
                ) : (
                  <div className="m-b-75 m-t-75">
                    <p>
                      Have a question for us? Click the button below to get it
                      touch!
                    </p>
                    <button
                      className="button button__secondary"
                      type="secondary"
                      data-tf-popup="t3vHk1tl"
                      data-tf-hide-headers
                      data-tf-hide-footer
                    >
                      Contact Form
                    </button>
                  </div>
                )}
              </div>
              <div className="contact-misc-wrap">
                <div>
                  <h3 className="m-b-5">Location & Hours</h3>
                  <a
                    href="https://www.google.com/search?q=directions+to+117+Sharptown+Auburn+Road%2C+Pilesgrove%2C+NJ+08098&rlz=1C5CHFA_enUS941US941&oq=directions+to+117+Sharptown+Auburn+Road%2C+Pilesgrove%2C+NJ+08098&aqs=chrome..69i57.3750j0j7&sourceid=chrome&ie=UTF-8"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="i"
                  >
                    117 Sharptown Auburn Road, Pilesgrove, NJ 08098
                  </a>
                  <ul className="m-t-5 ul">
                    {hours[0].fields.enotecaHours.map((x) => {
                      return (
                        <li>
                          <span className="b">{x.key}: </span>
                          {x.value}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div>
                  <h3>F.A.Q.</h3>
                  <ul className="ul">
                    <li>Can I bring my dog?</li>
                    <li>Can I bring food?</li>
                    <li>
                      <Link
                        className="underlined"
                        to="/faq"
                        onClick={toggleContactOverlay}
                      >
                        find the answers here...
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="contact-form-wrap">
                    <div className="form-input-wrap">
                      <h3>Join our email club</h3>
                      <p>
                        No cost, no commitment. Just a cool e-newsletter and a
                        lot of great benefits!
                      </p>
                      <form
                        action="https://squareup.com/outreach/tUfIZt/subscribe"
                        method="POST"
                        target="_blank"
                      >
                        <label className="w-100">Email</label>
                        <input
                          className="signup-input w-75"
                          type="email"
                          name="email_address"
                          placeholder="your email address"
                        />
                        <input type="hidden" name="embed" value="true" />
                        <Button
                          className="brush-button mt-2"
                          small
                          type="submit"
                        >
                          Join Now
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactOverlay;
