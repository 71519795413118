import React, { useEffect } from "react";
import moment from "moment";
import SheWolf from "../images/corner/corner-shewolf.webp";

const Press = ({ items }) => {
  useEffect(() => {
    document.title = "Auburn Road - In The Press";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);

  const pressItems = items.map((item) => {
    const { title, blurb, pressPostDate, link, author } = item.fields;
    return (
      <div className="m-t-50">
        <a rel="noopener noreferrer" target="_blank" href={link}>
          <h2 className="m-b-4">{title}</h2>
        </a>
        <span className="m-t-15 m-b-0">{blurb}</span>
        {author && (
          <p className="m-t-5 font-size--small fw-bold">
            <em>by {author}</em>
            <span> | {moment(pressPostDate).format("MMM D, YYYY")}</span>
          </p>
        )}
        <p className="m-b-5">
          <a rel="noopener noreferrer" target="_blank" href={link}>
            Read here
          </a>
        </p>
      </div>
    );
  });
  return (
    <>
      <img className="corner-shewolf" src={SheWolf} alt="" />
      <div className="container p-t-150 m-b-100 inner-container arv-csa">
        <div className="d-flex flex-column d-inline-block align-items-center m-b-100">
          <h1 className="huge-text-no-quote">Nice things people</h1>
          <span className="at-the-enoteca">say about us</span>
        </div>
        {pressItems}
      </div>
    </>
  );
};

export default Press;
